
  import { acquisitionTypeColors } from '@/utils/acquisitionTypeColors'
import { Component, Prop } from 'vue-property-decorator'
  import StaffDashboard from './StaffDashboard'

@Component
  export default class StaffStock extends StaffDashboard {
  @Prop({ default: true, type: Boolean }) showGeneralResults
  Promise = Promise

  // Methods
  beforeCreate () {
    this.getData = async () => {
      this.$set(this.chartData.currentMonth, 'stock', await this.getStock(this.currentMonthFilters.stockSpecs))
    }
  }

  async getGeneration (version, year) {
    return this.fetchData({
      query: { name: 'findLite', model: 'Generation' },
      filter: { version_year: { id_version: { _eq: version }, id_year: { _eq: year } } },
      force: true,
    })
  }

  async findAlternativesAttributes (auto) {
    if (!auto?.version?.version?.id || !auto?.version?.year?.id) return

    const getGenerations = await this.getGeneration(auto?.version?.version?.id, auto?.version?.year?.id)

    const generation = getGenerations?.length === 1 ? getGenerations[0] : getGenerations.find(generation => generation.id === auto.generation?.id)

    if (generation?.attributes?.length) {
      return generation?.attributes
    }

    const attributes = await this.fetchData({
      query: {
        name: 'find',
        model: 'Attribute',
      },
      filter: {
        _and: [
          { id_version_year: { _eq: auto.version.id } },
          { component: { slug: { _in: ['transmission', 'bodywork', 'fuel'] } } },
        ],
      },
      force: true,
    })

    if (attributes?.length) {
      return attributes
    }
  }

  async joinStockReserves (records: Record<string, any>[]): Promise<Record<string, any>> {
    if (!records) return
    const statusesToRemove = ['retired', 'sold']
    records = records.filter(record => {
      return !statusesToRemove.includes(record?.status?.status?.name)
    })
    const reserveStatus = (await this.fetchData({
      query: { name: 'find', model: 'ProcessStatus' },
      filter: {
        status: {
          name: { _eq: 'reserved' },
        },
      },
    }))[0].status
    for (let i = 0; i < records.length; i++) {
      if (records[i].status?.status?.name === 'reservation_process') {
        records[i].status.status = reserveStatus
      }
    }

    return this.setAcquisitionTypeColors(records)
  }

  setAcquisitionTypeColors (records) {
    return records.map(record => {
      const acquisitionType = record.stock.stockDetail.acquisitionType
      acquisitionType.color = acquisitionTypeColors[acquisitionType.name]
      record.stock.stockDetail.acquisitionType = acquisitionType
      return record
    })
  }
  // Getters

  // Watchers
  }
